<template>
  <div>
    <van-form @submit="onSubmit">
      <div class="tops">
        <div style="margin-top:3%">店铺名称：<label  runat="server" id="lb_Shop_Name">{{Dept_Name}}</label></div>
        <div><font color="#de3f00">店铺地址：</font><label  runat="server" id="lb_Shop_DZ">{{DP_DZ}}</label></div>
      </div>

      <van-field name="radio" label="是否实名投诉">
        <template #input>
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>

<!--      <van-field-->
<!--          v-model="Ts_title"-->
<!--          name="投诉标题"-->
<!--          label="投诉标题"-->
<!--          placeholder="请输入投诉标题"-->
<!--          :rules="[{ required: true, message: '请输入投诉标题' }]"-->
<!--      />-->
      <van-field
          readonly
          clickable
          name="picker"
          :value="Ts_title"
          label="投诉标题"
          placeholder="点击选择投诉标题"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择投诉标题' }]"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
          v-model="Ts_Cont"
          name="投诉内容"
          label="投诉内容"
          placeholder="投诉内容"
          :rules="[{ required: true, message: '请输入投诉内容' }]"
      />
      <van-field  label="照片上传">
        <template #input >
          <van-image
              width="100"
              height="100"
              :src='icont'
              @click="picSc()"
              v>
          </van-image>点击上传所需照片
        </template>


      </van-field>
      <van-field
          v-model="Name"
          name="姓名"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: this.radio=='1', message: '请输入姓名' }]"
      />
      <van-field
          v-model="LxFs"
          name="联系方式"
          label="联系方式"
          placeholder="联系方式"
          :rules="[{ required: this.radio=='1', message: '请输入联系方式' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">投诉</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Toast} from "vant";
import DES from "@/assets/js/DES";
import {mgop} from "@aligov/jssdk-mgop";
import Emas from "@/assets/js/Emas";

export default {
name: "index",
  data(){
    return{
      ShopID:'',
      token:'',
      Ts_title:'',
      Ts_Cont:'',
      DD_ID:'',
      Dept_Name:'',
      DP_DZ:'',
      radio: '1',
      uploader: [],
      Name:'',
      LxFs:'',
      icont:'' ,
      Info:'',
      columns: ['卫生', '秩序', '设施', '绿化', '地址'],
      showPicker:false
    }
  },created() {
    this.ShopID=this.$route.query.ID
    this.Dept_Name=this.$route.query.Dept_Name
    this.DP_DZ=this.$route.query.DP_DZ
    this.token=sessionStorage.getItem("token");
    // let  a="{\"result\":\"true\",\"picPath\":[\"https://portal.zjzwfw.gov.cn/media/oss/image/PORTAL/64D010EB27F6CD2B0FD8A33773D6BE15.jpg\"]}";
    // let b=JSON.parse(a);
    // this.icont=b.picPath[0]

    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },methods:{

    onConfirm(value) {
      this.Ts_title = value;
      this.showPicker = false;
    },
    picSc(){
      if(this.icont!=''){
        return;
      }
      ZWJSBridge.onReady(() => {
        ZWJSBridge.chooseImage({
          upload:true
        }).then((result) => {
          alert(result.result)
          if (result.result){
            alert(result.picPath)
            this.icont=result.picPath[0]
          }
        }).catch((error) => {
          alert(error)
        });
      })
    },
    async onSubmit(){
      Toast.loading({
        duration:0,
        message:'正在提交',
        forbidClick:true,
      });
      let strData='{"ShopID":"' + this.ShopID
          + '","Title":"'+this.Ts_title
          +'","Count":"'+this.Ts_Cont
          +'","Name":"'+this.Name
          +'","Phone":"'+this.LxFs
          +'","File_Path":"'+this.icont+'"}';
      console.log(strData)
      // alert(strData)
      this.Info=DES.encryptDes(strData)
      console.log(this.Info)
      mgop({
        api: "mgop.will.mqsb.SjYhTs", // 必须
        host: "https://mapi.zjzwfw.gov.cn/",
        dataType: "JSON",
        type: "POST",
        data: {token:this.token,type:'2', Info: this.Info },
        appKey: "3n3b3538+2001833223+ekdhqo", // 必须
        onSuccess: (data) => {
          //alert(JSON.stringify(data))
          console.log("mgop run success login : " + JSON.stringify(data));
          if (data.data.result == "ok") {
            if (data.data.msg==1){
              Toast.clear();
              Toast.success('提交成功');
              this.$router.push({
                path: '/index'
              })
            }else if(data.data.msg==2){
              Toast.clear();
              Toast.fail('提交失败');
              this.$router.push({
                path: '/index'
              })
            }else if(data.data.msg==3){
              Toast.clear();
              Toast.fail('当前地址没有网格管理');
              this.$router.push({
                path: '/index'
              })
            }else if(data.data.msg==4){
              Toast.clear();
              Toast.fail('未找到店铺');
              this.$router.push({
                path: '/index'
              })
            }
          } else if(data.data.result == "401"){
            Toast.fail('token失效，请重新登录');
            this.$router.push({
              path: '/index'
            })
          }else {
            Toast.clear();
            Toast.fail('提交失败');
            this.$router.push({
              path: '/index'
            })
          }
        },
        onFail: (err) => {
          console.log("mgop run error. error :  " + JSON.stringify(err));
          Toast.clear();
          Toast.fail('提交失败');
          this.$router.push({
            path: '/index'
          })
        },
      });
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/layui.css";
.tops {
  background: url(../../assets/images/ts_02.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  height:120px;
  font-size:18px;
  line-height:30px;
  padding:20px;
  color:#004e71;
}
.layui-row {
  margin-top: 20px;
}

body
{
  background: #f5fbff;
}
.layui-btn-normal {
  background:#48D0D0;
  color:#fff;
  border-radius:10px;
}
.layui-btn-danger{

  color:#fff;
  border-radius:10px;
}
</style>